<ng-template #newsletter>
  <form [formGroup]="form" (submit)="submit($event)">
    <h1 mat-dialog-title>Newsletter</h1>

    <mat-dialog-content>
      <section class="newsletter">
        <aside class="newsletter_top">

          <h4 class="newsletter_top_title">
            Para receber novidades, promoções, conteúdos e descontos em primeira mão, cadastre seu e-mail.
          </h4>

        </aside>

        <aside class="newsletterForm">
          <mat-form-field class="newsletterForm_container">
            <input matInput placeholder="Email" formControlName="email">
          </mat-form-field>
        </aside>

      </section>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button color="accent" [disabled]="form.pending || form.invalid">Enviar</button>
    </mat-dialog-actions>
  </form>
</ng-template>
