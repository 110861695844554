<ae2-unit-list-container>
  <ng-template #unitListTemplate let-results="results">
    <aside class="unit" *ngIf="results.length > 0">

      <mat-accordion>
        <ng-container *ngFor="let item of results; let i = index">
          <mat-expansion-panel [expanded]="i === 0">

            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="unit_container" fxLayout fxLayout.xs="column" fxLayout.sm="column">
              <div class="unit_container_item" fxFlex="30%" fxFlex.xs="auto">
                <ae2-staff-edit [adminUrl]="item.adminUrl">
                  <ng-template>
                    <div class="unit_container_item_image" *ngIf="item.largeImage" style.background-image="url({{ item.largeImage | ae2Thumbor:'700x'}})"></div>
                    <div class="unit_container_item_image" *ngIf="!item.largeImage" [ngStyle]="{'background': 'url(/assets/images/fallback.jpg)'}"></div>
                  </ng-template>
                </ae2-staff-edit>
              </div>
              <div class="unit_container_item" fxFlex="70%" fxFlex.xs="auto">
                <div class="unit_container_item_description" [innerHTML]="item.description"></div>
                <div class="unit_container_item_events">

                  <ae2-event-list-container sort="start_time" pageSize="3" nextEvents="true" [unitList]="[item.id]" [useLazyLoading]="true"
                    [useLoadAllPages]="false">
                    <ng-template let-events="results" let-books="eventBooks" let-autoBooks="eventAutoBooks">

                      <h2 *ngIf="events.length > 0" class="unit_container_item_events_title">Próximos eventos em {{item.name}}</h2>
                      <div *ngIf="events.length > 0" fxLayout fxLayout.xs="column" fxLayoutWrap>
                        <ng-container *ngFor="let event of events">

                          <ae2-event-widget [event]="event" [autoBooks]="autoBooks" [eventBooks]="books" fxFlex="32%" fxFlex.xs="auto" fxFlex.sm="47%"
                            class="unit_container_item_events_card">
                          </ae2-event-widget>

                        </ng-container>
                      </div>
                      <div class="viewMore">
                        <button mat-raised-button color="accent" routerLink="/agenda">Ver Agenda Completa</button>
                      </div>

                    </ng-template>
                  </ae2-event-list-container>

                </div>
              </div>
            </div>


            <div class="map" *ngIf="item.latitude && item.longitude">
              <div class="boxMap" fxLayout>
                <div class="boxMap_pin" fxFlex="20%"></div>
                <div class="boxMap_box" fxFlex="80%">
                  <div class="boxMap_box_title">Onde estamos</div>
                  <ae2-site-settings>
                    <ng-template ae2SiteSettingsContent let-site="site">
                      <div class="boxMap_box_address">
                        <p>{{ site?.address1 }}, {{site?.address2}}
                          <br>{{site?.address3}}, {{site?.address4}}
                          <br>{{site?.city}} - {{site?.state}}
                          <br> CEP {{ site?.zipCode }}</p>
                        <br>
                        <small>TELEFONE</small>
                        <p>{{ site?.phone }}
                          <br>{{ site?.mobile }}</p>
                      </div>
                    </ng-template>
                  </ae2-site-settings>
                </div>
              </div>
              <agm-map [latitude]="item.latitude" [longitude]="item.longitude" [zoom]="zoom" [styles]="customStyle" [scrollwheel]="false">
                <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
                </agm-marker>
              </agm-map>
            </div>

          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>

    </aside>
  </ng-template>
</ae2-unit-list-container>