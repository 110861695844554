import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { MatButtonModule } from '@angular/material';
import { ListUnitsModule } from '../../shared/components/list-units/list-units.module';
import { ActivitiesModule } from '../../shared/components/activities/activities.module';
import { TrainersModule } from '../../shared/components/trainers/trainers.module';
import { PackageModule } from '../../shared/components/package/package.module';
import { ContactModule } from '../../shared/components/contact/contact.module';
import { CalendarHomeModule } from '../../shared/components/calendar-home/calendar-home.module';
import { NewsletterModule } from '../../shared/components/newsletter/newsletter.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    Ae2CarouselContainerModule,
    ListUnitsModule,
    ActivitiesModule,
    TrainersModule,
    PackageModule,
    ContactModule,
    CalendarHomeModule,
    NewsletterModule,
    RouterModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
