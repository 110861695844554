import { OnInit, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';
import { MatDialog } from '@angular/material/dialog';
var NewsletterComponent = /** @class */ (function () {
    function NewsletterComponent(marketingService, matDialog) {
        this.marketingService = marketingService;
        this.matDialog = matDialog;
    }
    Object.defineProperty(NewsletterComponent.prototype, "data", {
        set: function (open) {
            if (open) {
                this.open();
            }
        },
        enumerable: true,
        configurable: true
    });
    NewsletterComponent.prototype.ngOnInit = function () {
        this.form = this.createForm();
    };
    NewsletterComponent.prototype.open = function () {
        this.matDialogRef = this.matDialog.open(this.newsletter);
    };
    NewsletterComponent.prototype.close = function () {
        this.matDialogRef.close();
    };
    NewsletterComponent.prototype.submit = function (event) {
        var _this = this;
        if (this.form.get('email').value === '') {
            return;
        }
        var data = new Ae2MarketingEmailsAcquired();
        data.email = this.form.get('email').value;
        data.source = 'https://soulride.com.br/';
        data.campaign = 'banner-home';
        this.marketingService.subscribeUser(data).subscribe(function (res) {
            if (res.email) {
                _this.form.reset();
                _this.close();
            }
        });
    };
    NewsletterComponent.prototype.createForm = function () {
        return new FormGroup({
            email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
        });
    };
    return NewsletterComponent;
}());
export { NewsletterComponent };
