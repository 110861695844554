<ae2-package-list-container>
    <ng-template #packageListTemplate let-packages="results">
        <section class="package" *ngIf="packages.length > 0">
            <aside class="package_header">
                <h1 class="package_header_title">Pacotes</h1>
                <p>Confira os planos disponíveis para pedalar com a gente</p>
            </aside>
            <aside class="package_content">

                <div fxLayout.xs="column" fxLayout.gt-xs="row wrap" fxLayoutGap.xs="15px" fxLayoutGap.gt-xs="5px" fxLayoutAlign="center center">
                    <ng-container *ngFor="let package of packages">
                        <ae2-package-widget [data]="package"></ae2-package-widget>
                    </ng-container>
                </div>

            </aside>
        </section>
    </ng-template>

</ae2-package-list-container>