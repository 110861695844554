import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  @Input('open')
  set data(open: boolean) {
    if (open) {
      this.open();
    }
  }

  @ViewChild('newsletter')
  newsletter: TemplateRef<any>;

  form: FormGroup;

  private matDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    private marketingService: Ae2MarketingService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  open(): void {
    this.matDialogRef = this.matDialog.open(this.newsletter);
  }

  close(): void {
    this.matDialogRef.close();
  }

  submit(event: Event): void {
    if (this.form.get('email').value === '') {
      return;
    }
    const data = new Ae2MarketingEmailsAcquired();
    data.email = this.form.get('email').value;
    data.source = 'https://soulride.com.br/';
    data.campaign = 'banner-home';
    this.marketingService.subscribeUser(data).subscribe(res => {
      if (res.email) {
        this.form.reset();
        this.close();
      }
    });
  }
  protected createForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
    });
  }

}
