import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {

  instrutor: any;
  ngOnInit(): void {
  }
  setInstructor(instructor: any): void {
    this.instrutor = instructor;
  }
}
