/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "./calendar-home.component";
var styles_CalendarHomeComponent = [i0.styles];
var RenderType_CalendarHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarHomeComponent, data: {} });
export { RenderType_CalendarHomeComponent as RenderType_CalendarHomeComponent };
export function View_CalendarHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "calendarHome"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "aside", [["class", "calendarHome_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "calendarHome_header_content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pr\u00F3ximos eventos "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "calendarHome_header_content_button"], ["mat-raised-button", ""], ["routerLink", "/agenda"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["Agenda Completa"]))], function (_ck, _v) { var currVal_2 = "/agenda"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_CalendarHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calendar-home", [], null, null, null, View_CalendarHomeComponent_0, RenderType_CalendarHomeComponent)), i1.ɵdid(1, 114688, null, 0, i8.CalendarHomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarHomeComponentNgFactory = i1.ɵccf("app-calendar-home", i8.CalendarHomeComponent, View_CalendarHomeComponent_Host_0, {}, {}, []);
export { CalendarHomeComponentNgFactory as CalendarHomeComponentNgFactory };
