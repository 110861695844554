import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { Ae2FooterModule } from '@angularecommerce/core/components/footer';

@NgModule({
  imports: [
    Ae2FooterModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
