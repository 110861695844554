import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-home',
  templateUrl: './calendar-home.component.html',
  styleUrls: ['./calendar-home.component.scss']
})
export class CalendarHomeComponent implements OnInit {

  ngOnInit(): void {
  }

}
